import { FiMail } from '@react-icons/all-files/fi/FiMail';
import { FiMapPin } from '@react-icons/all-files/fi/FiMapPin';
import { FiPhone } from '@react-icons/all-files/fi/FiPhone';
import { PageProps, graphql } from 'gatsby';
import React from 'react';
import { Stack } from '../../components/Core';
import Flags, { Languages } from '../../components/Flags';
import IconWithText from '../../components/IconWithText';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import PostsSection from '../../components/PostsSection';
import SEO from '../../components/SEO';
import SectionWithTitle from '../../components/SectionWithTitle';
import StyledLink from '../../components/StyledLink';
import Text from '../../components/Text';
import { TEXT_STYLES } from '../../components/Text/constants';
import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';

type SingleStaffProps = PageProps<Queries.StaffSingleQuery, IPageContext>;

const STATIC_STRINGS = {
  en: {
    formation: 'Formation',
    presentation: 'Presentation',
    articles: 'My articles',
  },
  fr: {
    formation: 'Formation',
    presentation: 'Présentation',
    articles: 'Mes articles',
  },
};

const SingleStaff = ({ data, pageContext }: SingleStaffProps) => {
  const pageData = data.wpStaff;

  if (!pageData || !pageContext.defaultLanguage) return null;

  const page = pageData;
  const photo = page.staffInformations.photo;

  const centre = data.allWpCentre.nodes.find((centre: any) =>
    centre.servicesEtStaff.staff.some((staff: any) => staff.id === page.id)
  );

  const posts = data.allWpPost.nodes.filter((post: any) => {
    if (!post.informationsPost.auteur?.membre) return false;

    // Check if there's some post from the same author
    return post.informationsPost.auteur.membre.some(
      (membre: any) => membre.id === page.id
    );
  });

  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap mt-gap">
        <SectionWithTitle className="relative z-1">
          <Stack className="w-full space-y-12">
            <Text
              variant={TEXT_STYLES.H5}
              className="uppercase text-primaryMain"
            >
              Staff
            </Text>

            <Text variant={TEXT_STYLES.H1} className="!mt-2">
              {page.staffInformations?.prenom} {page.staffInformations?.nom}
            </Text>

            <Text
              variant={TEXT_STYLES.H2}
              className="!normal-case !mt-2 !text-5 md:!text-6 text-primaryMain"
            >
              {page.staffInformations.poste?.[0]?.title}
            </Text>

            <div className="grid md:grid-cols-[400px_1fr] gap-16">
              <div className="relative">
                <figure className="relative before:absolute before:inset-0 before:translate-y-4 before:translate-x-4 before:bg-primaryMain !my-0">
                  <Image
                    imageClassName="!max-h-[none] w-full [&>div:first-child]:!max-w-none mt-0 mb-0"
                    image={serializeImageForImageComponent({ image: photo })}
                    className="w-full h-auto max-w-none"
                  />
                </figure>

                {page.staffInformations.languages && (
                  <ul className="max-w-[300px] relative z-10 grid grid-cols-4 gap-2 pl-6 -mt-2 list-none lg:pl-10">
                    {page.staffInformations.languages.map(
                      (language: Languages) => (
                        <li
                          key={`user-${page.staffInformations.lastName}-${language}`}
                        >
                          <Flags lang={language} />
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>

              <div>
                {page.hero.tagline && (
                  <Text variant={TEXT_STYLES.H5} className="mb-6 uppercase">
                    {page.hero.tagline}
                  </Text>
                )}

                {page.staffInformations.description && (
                  <Text variant={TEXT_STYLES.P} className="mb-8">
                    {page.staffInformations.description}
                  </Text>
                )}

                <div className="p-6 text-white bg-secondaryMain md:max-w-[420px]">
                  {centre && centre?.title && (
                    <Text
                      variant={TEXT_STYLES.H4}
                      className="text-white uppercase !text-xl lg:!text-2xl"
                    >
                      <IconWithText
                        description={centre?.title}
                        icon={FiMapPin}
                        iconClassName="w-5 h-5 sm:w-6 sm:h-6"
                        isInline
                        textClassName="text-xl font-bold leading-5 !font-heading lg:text-2xl xxl:text-3xl"
                      />
                    </Text>
                  )}

                  {page.staffInformations?.gsm && (
                    <StyledLink
                      shouldObfuscate
                      isPhone
                      to={page.staffInformations?.gsm}
                      className="text-xl lg:text-2xl"
                    >
                      <IconWithText
                        description={page.staffInformations?.gsm}
                        icon={FiPhone}
                        isInline
                        iconClassName="w-5 h-5 sm:w-6 sm:h-6"
                        textClassName="text-xl font-bold leading-5 !font-heading lg:text-2xl xxl:text-3xl hover:underline"
                      />
                    </StyledLink>
                  )}

                  {page.staffInformations?.email && (
                    <StyledLink
                      shouldObfuscate
                      isEmail
                      to={page.staffInformations?.email}
                      // fontSize={{ base: 20, lg: 24, xxl: 30 }}
                      // color="white"
                      className="text-xl lg:text-2xl"
                    >
                      <IconWithText
                        description={page.staffInformations?.email}
                        icon={FiMail}
                        isInline
                        iconClassName="w-5 h-5 sm:w-6 sm:h-6"
                        textClassName="text-xl font-bold leading-5 !font-heading lg:text-2xl xxl:text-3xl hover:underline"
                      />
                    </StyledLink>
                  )}

                  {(page.staffInformations.phoneLink ||
                    page.staffInformations.externalLink) && (
                    <StyledLink
                      isPhone={page.staffInformations.linkType === 'phone'}
                      shouldObfuscate={
                        page.staffInformations.linkType === 'phone'
                      }
                      target="_blank"
                      to={
                        page.staffInformations.linkType === 'phone'
                          ? page.staffInformations.phoneLink
                          : undefined
                      }
                      href={
                        page.staffInformations.linkType === 'link'
                          ? page.staffInformations.externalLink
                          : undefined
                      }
                      className="relative z-20 inline-flex w-auto px-4 py-2 mx-0 mt-0 font-bold !text-dark uppercase translate-y-10 text-4 bg-primaryMain font-heading"
                    >
                      {page.staffInformations.textLink ??
                        page.staffInformations?.translations[
                          pageContext.currentLanguageSlug ?? 'fr'
                        ]?.appointment}
                    </StyledLink>
                  )}
                </div>
              </div>
            </div>
          </Stack>
        </SectionWithTitle>

        {page.staffInformations.presentation && (
          <Stack>
            <Text variant={TEXT_STYLES.SECTION} as={'h2'} className="mb-4">
              {STATIC_STRINGS[pageContext.currentLanguageSlug]?.presentation}
            </Text>

            <Text variant={TEXT_STYLES.P} className="mb-8">
              {page.staffInformations.presentation}
            </Text>
          </Stack>
        )}

        {page.staffInformations.formation && (
          <Stack>
            <Text variant={TEXT_STYLES.SECTION} as={'h2'} className="mb-4">
              {STATIC_STRINGS[pageContext.currentLanguageSlug]?.formation}
            </Text>

            <Text variant={TEXT_STYLES.P} className="mb-8">
              {page.staffInformations.formation}
            </Text>
          </Stack>
        )}

        {posts.length > 0 && (
          <Stack className="mt-16">
            <Text variant={TEXT_STYLES.H2} className="mb-8">
              {STATIC_STRINGS[pageContext.currentLanguageSlug]?.articles}
            </Text>

            <PostsSection
              currentLanguageCode={pageContext.currentLanguage.code}
              displayedCategoriesSlugs={undefined}
              title={undefined}
              description={undefined}
              posts={posts}
            />
          </Stack>
        )}
      </Stack>
    </Layout>
  );
};

export default SingleStaff;

export const query = graphql`
  query StaffSingle($id: String!, $currentLanguageSlug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allWpConfiguration {
      nodes {
        configuration {
          textes {
            voirPlus
          }
        }
      }
    }

    wpStaff(id: { eq: $id }) {
      ...TeamMemberFragment

      hero {
        tagline
      }

      language {
        locale
        slug
      }
      seo {
        ...SEOFragment
      }
    }
    allWpCentre(
      limit: 100000
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        title
        slug
        servicesEtStaff {
          staff {
            ... on WpStaff {
              id
            }
          }
        }
      }
    }

    allWpPost(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostEntryFragment
      }
    }
  }
`;
